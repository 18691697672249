import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "linkUrl", "blankLinkUrl" ]

  connect() {
    console.log(this.data.get('blank-link-url'))
    this.toggleLinkUrl(
      this.data.has('blank-link-url') && (
        this.data.get('blank-link-url') === 'false'
      )
    )
  }

  doChangeBlanklinkUrl(event) {
    this.toggleLinkUrl(!$(event.target).is(':checked'))
  }

  toggleLinkUrl(hasLink) {
    if (!this.hasLinkUrlTarget) { return }

    if (hasLink) {
      $(this.linkUrlTarget).show()
    } else {
      $(this.linkUrlTarget).hide()
    }
  }
}
